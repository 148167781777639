<template>
  <pcis-steps :form-type="formType" ref="steps" :enableButtons="false">
    <div class="loading" v-loading="true" :element-loading-text="$t('common.loading.process')" />
  </pcis-steps>
</template>

<style scoped>

@media screen and (max-width: 709px) {
  .loading {
    min-height: calc(100vh - 214px);
    width: 100%;
  }
}

@media screen and (min-width: 710px) {
  .loading {
    min-height: calc(100vh - 197px);
    width: 100%;
  }
}
</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";
// import { ConfirmDialog } from "@/plugins/message";
// import authentication from "../../../http/apis/personal/authentication";
import store from "../../../store";
import { authenticateJWT } from "../../../libs/common";
import i18n from "../../../language/index.js";

export default {
  extends: BaseStep,
  name: "PersonalPaymentProcess",
  methods: {
    onPrevClick() {
      this.$refs.steps.goPrev();
    }
  },
  mounted() {
    let personal = store.state.personal;
     if (!personal.form.id) {
      this.$refs.steps.goForm();
      return false;
    }
    if(!authenticateJWT(personal.jwt)) return;
    this.onPrevClick();
  },
  async beforeRouteEnter(to, from, next) {
    let personal = store.state.personal;
    if (i18n.global.locale !== personal.locale && personal.locale) {
      next({
        name: to.name,
        params: {
          locale: personal.locale
        },
        query: to.query
      })
    } else {
      next();
    }
  }
};
</script>
